import { motion, useScroll } from "framer-motion";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useScrollFunctions } from "./useScrollFunctions";
import { useAtomValue } from "jotai";
import { textIndex } from "./atoms";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import WWW from "./www";
import Footer from "../footer";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const { scrollY } = useScroll();
  const [scrolling, brightness] = useScrollFunctions();
  const activeTextIndex = useAtomValue(textIndex);

  useEffect(() => {
    const unsubscribe = scrollY.onChange((latest) => scrolling(latest));

    return () => unsubscribe();
  }, [scrollY]);

  useGSAP(() => {
    gsap.fromTo(
      ".navbar-text",
      { color: "white" },
      {
        color: "black",
        scrollTrigger: {
          trigger: ".test",
          start: "top 10%",
          end: "10% 10%",
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".logo",
      { filter: "invert(0)" },
      {
        filter: "invert(1)",
        scrollTrigger: {
          trigger: ".test",
          start: "top 10%",
          end: "10% 10%",
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".burger",
      { filter: "invert(0)" },
      {
        filter: "invert(1)",
        scrollTrigger: {
          trigger: ".test",
          start: "top 10%",
          end: "10% 10%",
          scrub: true,
          markers: false,
        },
      }
    );
  });

  return (
    <div className="overflow-x-clip">
      <section className="relative w-screen h-[350vh]">
        <img
          className="fixed w-full h-full object-cover object-center overflow-hidden"
          src="https://endthesilence.github.io/recources/people.png"
          style={{
            filter: `brightness(${brightness}%)`
          }}
        />
        <div className="relative top-[40vh] inset-0 flex items-center justify-center flex-col lg:gap-2 overflow-x-hidden p-6">
          <div className="overflow-hidden w-full">
            <motion.h1
              className="w-full text-white text-3xl lg:text-6xl font-bold text-left"
              initial={{ y: -100 }}
              animate={{ y: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
            >
              Realität teilen, Bewusstsein schaffen.
            </motion.h1>
          </div>
          <div className="overflow-hidden w-full">
            <motion.h1
              className="w-full text-white text-3xl lg:text-6xl font-bold text-left"
              initial={{ y: -100 }}
              animate={{ y: 0 }}
              transition={{ duration: 1, ease: "easeOut", delay: 0.6 }}
            >
              Von uns, für dich.
            </motion.h1>
          </div>
        </div>

        <div className="overflow-clip mt-[90vh] mb-[50vh] flex items-center justify-center flex-col text-4xl sticky top-[35%] centered-text">
          <motion.div className="text-xl p-4 lg:p-0 lg:text-3xl font-bold lg:w-[60vw] text-center">
            <p>
              <span
                className={
                  activeTextIndex === 0 ? "text-white" : "text-gray-500"
                }
              >
                In einer Welt voller perfekter Fassaden ist es wichtig, echte
                Geschichten zu teilen.
              </span>
              <span
                className={
                  activeTextIndex === 1 ? "text-white" : "text-gray-500"
                }
              >
                Indem wir unsere Erfahrungen auf Social Media zeigen, brechen
                wir das Schweigen und schaffen Verständnis.
              </span>
              <span
                className={
                  activeTextIndex === 2 ? "text-white" : "text-gray-500"
                }
              >
                "Realität teilen, Bewusstsein schaffen" bedeutet, unsere wahren
                Erlebnisse zu zeigen und das Bewusstsein für
                Alltagsherausforderungen zu schärfen.
              </span>
              <span
                className={
                  activeTextIndex === 3 ? "text-white" : "text-gray-500"
                }
              >
                Gemeinsam können wir das Stigma bekämpfen und echte Veränderung
                bewirken.
              </span>
            </p>
          </motion.div>
        </div>
      </section>
      {/* Abschnitt, der das Bild überdeckt */}
      <section className="relative bg-secondary w-screen z-20 overflow-hidden test">
        <WWW />
        <section className="px-3 lg:px-20 mt-32 mb-48">
          <p className="text-gray-500 text-2xl">Mehr über uns.</p>
        </section>
        <Footer />
      </section>
    </div>
  );
};
export default Home;
