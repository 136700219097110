import { MenuIcon, X } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { cn } from "../utils/cn";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="fixed h-20 w-full bg-transparent z-50 flex justify-between items-center">
      <Link to="/">
        <img
          className={cn("logo relative h-10 px-4 lg:px-10 z-50", open && "invert")}
          src="https://endthesilence.github.io/recources/logo.svg"
          alt="Logo"
        />
      </Link>
      <div className="text-white gap-28 flex-row px-20 text-base navbar-text hidden lg:flex">
        <Link to="/sexualized-violence">
          <p className="hover:-translate-y-0.5 transition-transform">
            Sexualisierte Gewalt
          </p>
        </Link>
        <Link to="/engage">
          <p className="hover:-translate-y-0.5 transition-transform">
            Engagieren
          </p>
        </Link>
      </div>
      <div className="lg:hidden px-6 z-40">
        <button onClick={() => setOpen(!open)}>
          {open ? (
            <X
              color="black"
              className="relative z-50"
              size={34}
            />
          ) : (
            <MenuIcon color="white" size={34} className="burger" />
          )}
        </button>
        <div className="absolute bg-secondary h-screen w-screen top-0 pt-28 transition-all" style={{
          left: !open ? "100%": 0
        }}>
          <div className="flex flex-col gap-12 text-right w-full px-14">
            <Link to="/sexualized-violence" onClick={() => setOpen(false)}>
              <p className="text-2xl">Sexualisierte Gewalt</p>
            </Link>
            <Link to="/engage" onClick={() => setOpen(false)}>
              <p className="text-2xl">Engagieren</p>
            </Link>
            <Link to="/data-privacy" onClick={() => setOpen(false)}>
              <p className="text-2xl">Datenschutz</p>
            </Link>
            <Link to="/impressum" onClick={() => setOpen(false)}>
              <p className="text-2xl">Impressum</p>
            </Link>
            <Link to="/contact" onClick={() => setOpen(false)}>
              <p className="text-2xl">Kontakt</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
