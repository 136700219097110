import { useAtom } from "jotai"
import { currentTopic } from "./atoms"
import { cn } from "../../utils/cn"

interface buttonProps {
  children: string | JSX.Element | JSX.Element[]
  topic: string
}

const Button: React.FC<buttonProps> = ({children, topic}) => {
  const [selectedTopic, setTopic] = useAtom(currentTopic)

  return (
    <button onClick={() => setTopic(topic)} className={cn("p-2 border rounded-full border-black px-2 md:px-5 lg:px-8", selectedTopic === topic && "bg-black text-white")}>
      {children}
    </button>
  )
}
export default Button;