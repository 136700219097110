import { useAtomValue } from "jotai";
import Button from "./button";
import { currentTopic } from "./atoms";

const WWW = () => {
  const topic = useAtomValue(currentTopic);

  return (
    <section className="pt-52 px-3 lg:px-20">
      <p className="text-2xl lg:text-4xl font-bold text-black">
        Weil gemeinsam mehr geht.
      </p>
      <p className="text-left text-xl lg:text-3xl text-black">Von uns, für dich, durch dich.</p>

      <div className="flex flex-col lg:flex-row mt-10">
        <div className="bg-white rounded-3xl flex justify-between gap-4 flex-row lg:flex-col p-4">
          <Button topic="weshalb"><p className="text-xs lg:text-base">Weshalb?</p></Button>
          <Button topic="wodurch"><p className="text-xs lg:text-base">Wodurch?</p></Button>
          <Button topic="wie"><p className="text-xs lg:text-base">Wie?</p></Button>
        </div>
        <div className="p-2 pl-8">
          {topic === "weshalb" && (
            <p>
              Social Media hat das Potenzial, ein Ort des Austauschs und der
              Unterstützung zu sein – ein Raum, in dem Menschen sich offen und
              ohne Angst mitteilen können. Doch die Realität sieht oft anders
              aus. Selbst auf diesen Plattformen herrschen Stigmatisierung und
              Schweigen vor, besonders wenn es um Themen geht, die uns alle
              betreffen, aber aus Scham oder Angst nicht angesprochen werden.
              Mit „EndTheSilence“ möchten wir genau diesen Themen und den
              betroffenen Menschen eine Stimme geben, die sie alleine nie
              bekommen würden. Es ist unser Ziel, den Betroffenen einen sicheren
              Raum zu bieten, in dem sie ihre Geschichten erzählen können, ohne
              Angst vor Verurteilung oder Scham. <br />
              „EndTheSilence“ richtet sich aber nicht nur an die Opfer, sondern
              auch an die Gesellschaft als Ganzes. Wir wollen Bewusstsein
              schaffen, Diskussionen anstoßen und Tabus brechen. Es ist an der
              Zeit, dass wir über das Unausgesprochene sprechen, dass wir das
              Schweigen beenden und gemeinsam für eine Welt eintreten, in der
              jeder Mensch sich sicher und gehört fühlen kann. Social Media kann
              und sollte ein Ort des Austauschs und der Unterstützung sein – und
              genau das möchten wir mit „EndTheSilence“ erreichen.
            </p>
          )}
          {topic === "wodurch" && (
            <p>
              Im Jahr 2020 wurde Jonathan Drefs, der Gründer von EndTheSilence,
              Opfer eines schweren sexuellen Missbrauchs im Kindesalter von 12
              Jahren. Noch im selben Jahr trat seine Schwester, Lena Sophie
              Drefs, zunehmend in die Öffentlichkeit, und gemeinsam erkannten
              sie die Bedeutung ihrer Reichweite, um der Gesellschaft einen
              Mehrwert zu bieten. Jonathan war sich von Anfang an bewusst, dass
              er seine Stimme nutzen wollte, um über seine Erfahrungen zu
              sprechen und anderen Betroffenen eine Plattform zu bieten. Um den
              Missbrauch zu verarbeiten, befand sich Jonathan insgesamt 3,5
              Jahre in Psycho- und Traumatherapie. Diese Zeit war nicht nur für
              seine Verarbeitung entscheidend, sondern auch für die Vorbereitung
              darauf, seine Geschichte mit der Welt zu teilen – ein Ziel, das er
              seit Jahren verfolgt. Seit November 2023 befindet sich
              EndTheSilence in der Entwicklung und Planung. Für Jonathan und
              Lena ist es von größter Bedeutung, den Themen von EndTheSilence
              mit dem nötigen Respekt zu begegnen. Aus diesem Grund nehmen sie
              sich die Zeit, um eine Kampagne zu schaffen, die wirklich etwas
              bewirkt. Es ist ihnen wichtig, dass EndTheSilence nicht nur ein
              weiteres Projekt ist, sondern eine nachhaltige Wirkung erzielt.
            </p>
          )}
          {topic === "wie" && (
            <p>
              Die Vielfalt der Gesellschaft zu nutzen, um langfristigen Mehrwert
              zu schaffen, ist das Herzstück der Kampagne „EndTheSilence“. Es
              geht darum, nicht nur Menschen zusammenzubringen, sondern auch das
              Bewusstsein für stigmatisierte Themen zu schärfen und ins
              öffentliche Bewusstsein zu rücken. Durch das Engagement jedes
              Einzelnen – sei es durch das Teilen persönlicher Geschichten, das
              Informieren über tabuisierte Themen oder das Gespräch darüber –
              können wir nachhaltige Veränderungen bewirken. Mit gezielter
              Nutzung von Social Media und Reichweite schaffen wir Verbindungen
              zwischen Individuen, Institutionen und staatlichen Stellen, um
              unsichtbare Themen sichtbar zu machen und den Betroffenen eine
              Stimme zu geben. Unser Ziel ist es, die Gesellschaft zu
              sensibilisieren, das Schweigen zu brechen und zu zeigen, dass
              Betroffene eine Zukunft haben, die nicht durch ihre Vergangenheit
              definiert wird. Die Vielfalt der Gesellschaft ist unsere Stärke,
              und gemeinsam können wir eine Welt schaffen, in der jeder gehört
              wird und das Bewusstsein für diese wichtigen Themen wächst.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};
export default WWW;
