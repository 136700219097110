import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { motion, useScroll } from "framer-motion";
import Footer from "../footer";
import { useEffect, useLayoutEffect } from "react";
import { useScrollImage } from "./useScrollImage";

const Engage = () => {

  const { scrollY } = useScroll();
  const [scrolling, factor2, height2, factor3, height3] = useScrollImage();

  useEffect(() => {
    const unsubscribe = scrollY.onChange((latest) => scrolling(latest));

    return () => unsubscribe();
  }, [scrollY]);

  useGSAP(() => {
    gsap.fromTo(
      ".navbar-text",
      { color: "white" },
      {
        color: "black",
        scrollTrigger: {
          trigger: ".engage-2-parent",
          start: "18% 5%",
          end: "18% 5%",
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".logo",
      { filter: "invert(0)" },
      {
        filter: "invert(1)",
        scrollTrigger: {
          trigger: ".engage-2-parent",
          start: "18% 5%",
          end: "18% 5%",
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".burger",
      { filter: "invert(0)" },
      {
        filter: "invert(1)",
        scrollTrigger: {
          trigger: ".engage-2-parent",
          start: "18% 5%",
          end: "18% 5%",
          scrub: true,
          markers: false,
        },
      }
    );

    ////////////////////////////

    gsap.fromTo(
      ".navbar-text",
      { color: "black" },
      {
        color: "white",
        scrollTrigger: {
          trigger: ".engage-3-parent",
          start: "14% 0%",
          end: "14% 0%",
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".logo",
      { filter: "invert(1)" },
      {
        filter: "invert(0)",
        scrollTrigger: {
          trigger: ".engage-3-parent",
          start: "14% 0%",
          end: "14% 0%",
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".burger",
      { filter: "invert(1)" },
      {
        filter: "invert(0)",
        scrollTrigger: {
          trigger: ".engage-3-parent",
          start: "14% 0%",
          end: "14% 0%",
          scrub: true,
          markers: false,
        },
      }
    );

    /////////////////////////

    gsap.fromTo(
      ".navbar-text",
      { color: "white" },
      {
        color: "black",
        scrollTrigger: {
          trigger: ".end",
          start: "0% 10%",
          end: "0% 10%",
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".logo",
      { filter: "invert(0)" },
      {
        filter: "invert(1)",
        scrollTrigger: {
          trigger: ".end",
          start: "0% 10%",
          end: "0% 10%",
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".burger",
      { filter: "invert(0)" },
      {
        filter: "invert(1)",
        scrollTrigger: {
          trigger: ".end",
          start: "0% 10%",
          end: "0% 10%",
          scrub: true,
          markers: false,
        },
      }
    );
  })

  return (
    <div>
      <section>
        <img
          src="https://endthesilence.github.io/recources/engage-1.jpg"
          alt="Engage 1"
          className="absolute h-full w-full object-cover brightness-50 object-center"
        />
        <div className="relative pt-[40vh] text-center flex flex-col justify-center text-white gap-4">
          <p className="text-xl lg:text-2xl font-bold">
            Engagieren bei EndTheSilence
          </p>
          <p className="text-3xl lg:text-4xl font-bold">
            Sei die Veränderung, die du sehen willst.
          </p>
          <p className="px-6 text-sm lg:text-xl lg:px-80">
            Erlebe den Unterschied, den deine Einzigartigkeit in unserer
            Kampagne ausmacht. Deine Ideen und Beiträge sind wertvoll, denn nur
            durch dich können wir langfristig unser Ziel erreichen. Gemeinsam
            entwickeln wir uns weiter und feiern jede innovative Idee, die uns
            hilft, in vielen Bereichen positive Veränderungen zu erzielen."
          </p>
        </div>
      </section>

      <section className="engage-2-parent bg-white pt-[60vh] w-full justify-center flex flex-col">
        <div className="text-center flex gap-3 flex-col">
          <p className="text-xl font-bold">Community Engagment</p>
          <p className="text-3xl font-bold">
            Ehrenamt, das
            <br />
            bei jedem ankommt.
          </p>
        </div>
        <div className="flex justify-center pt-16">
          <img
            className="rounded-3xl brightness-50 object-center engage-2-desktop hidden lg:block"
            src="https://endthesilence.github.io/recources/engage-2.png"
            alt="Engage 2"
            style={{
              width: `${70 + (30 * factor2)}%`,
              borderRadius: `${1.5 - (1.5 * factor2)}rem`
            }}
          />
          <img
            className="absolute object-cover rounded-3xl brightness-50 object-center engage-2-mobile h-[20%] lg:hidden"
            src="https://endthesilence.github.io/recources/engage-2.png"
            alt="Engage 2"
            style={{
              height: `${20 + (90 * factor2)}%`,
              borderRadius: `${1.5 - (1.5 * factor2)}rem`
            }}
          />
          <div className="absolute flex justify-start w-[98vw] mt-[50vh] overflow-hidden lg:px-20">
            <motion.h1
              className="text-white text-left lg:w-[700px] engage-2-title"
              initial={{ y: -300 }}
            >
              <p className="text-3xl font-bold">
                “Bei EndTheSilence kann ich nicht nur für die Menschen meiner
                Region Ehrenamt machen, sondern für ganz Deutschland.”
              </p>
              <p className="text-2xl font-thin mt-4">
                Mijail, Community Engagment
              </p>
            </motion.h1>
          </div>
        </div>
      </section>
      <section className="mt-[100vh] lg:mt-[0vh] pt-40 px-2 lg:px-20">
        <div>
          <p className="text-3xl font-bold">Community Engagment</p>
          <p className="text-5xl font-bold">
            Weil Verantwortung unsere größte
            <br />{" "}
            <span className="bg-gradient-to-r from-[#A7CDF0] to-[#4F677C] bg-clip-text text-transparent">
              Verpflichtung
            </span>{" "}
            ist.
          </p>
        </div>
        <div className="flex lg:flex-row flex-col lg:gap-32 text-3xl justify-center mt-28 text-gray-500">
          <p className="">
            Wenn wir Öffentlich über das Sprechen, was uns widerfahren ist,
            können wir denjenigen zeigen, die sich grade alleine fühlen, dass
            sie es nicht sind. Doch dieser Prozess ist für jeden eine große
            Herausforderung. Für diesen Schritt ist das Community Engagement
            eine elementarer Bestandteil.
          </p>
          <p className="">
            Das Communuity Engagment unterstützt Betroffene wenn sie ihre
            Geschichte mit EndTheSilence teilen. Hierdurch wird Sichergestellt
            dass jeder Person einen festen Ansprechpartner für Wünsche, Gefühle
            und Anregungungen hat,
          </p>
        </div>
        <div className="w-full bg-gray-200 rounded-xl mt-20 flex justify-center">
          <p className="text-3xl text-[#0874CB] py-16">
            Aktuell keine Bewerbung möglich.
          </p>
        </div>
      </section>

      <section className="engage-3-parent bg-white mt-[20vh] w-full justify-center flex flex-col">
        <div className="text-center flex gap-3 flex-col">
          <p className="text-xl font-bold">Community Engagment</p>
          <p className="text-3xl font-bold">
            Ehrenamt, das
            <br />
            bei jedem ankommt.
          </p>
        </div>
        <div className="flex justify-center pt-16">
          <img
            className="rounded-3xl brightness-50 object-center engage-3-desktop w-[70%] hidden lg:block"
            src="https://endthesilence.github.io/recources/engage-3.jpg"
            alt="Engage 3"
            style={{
              width: `${70 + (30 * factor3)}%`,
              borderRadius: `${1.5 - (1.5 * factor3)}rem`
            }}
          />
          <img
            className="absolute object-cover rounded-3xl brightness-50 object-center engage-3-mobile h-[20%] lg:hidden"
            src="https://endthesilence.github.io/recources/engage-3.jpg"
            alt="Engage 3"
            style={{
              height: `${20 + (90 * factor3)}%`,
              borderRadius: `${1.5 - (1.5 * factor3)}rem`
            }}
          />
          <div className="absolute flex justify-start w-[98vw] mt-[50vh] overflow-hidden lg:px-20">
            <motion.h1
              className="text-white text-left lg:w-[700px] engage-3-title"
              initial={{ y: -300 }}
            >
              <p className="text-3xl font-bold">
                “Durch EndTheSilence kreieren wir Inhalte die Helfen. Wir nutzen
                das potenzial von Social Media für das wichtigste, einen
                Mehrwert zu erschaffen.”
              </p>
              <p className="text-2xl font-thin mt-4">Lukas, Content Team</p>
            </motion.h1>
          </div>
        </div>
      </section>

      <section className="end mt-[100vh] lg:mt-[0vh] pt-40 px-2 lg:px-20 mb-40">
        <div>
          <p className="text-3xl font-bold">Content Team</p>
          <p className="text-5xl font-bold">
            Mehr als Social Media,
            <br />{" "}
            <span className="bg-gradient-to-r from-[#F0D5A7] to-[#4F677C] bg-clip-text text-transparent">
              wir schaffen Veränderung.
            </span>
          </p>
        </div>
        <div className="flex lg:flex-row flex-col lg:gap-32 text-3xl justify-center mt-28 text-gray-500">
          <p className="">
            Auf Instagram werden pro Sekunden 1074 Bilder hochgeladen. Unser
            Content Team hat das Ziel das moderne Social Media so zu nutzen dass
            wichtige Inhalte und Themen breit veröffentlicht werden.
          </p>
          <p className="">
            Denn wir möchten Realität auf Social Media ermöglichen. Realität
            zwischen den Millionen Beiträgen täglich.
          </p>
        </div>
        <div className="w-full bg-gray-200 rounded-xl mt-20 flex justify-center">
          <p className="text-3xl text-[#0874CB] py-16">
            Aktuell keine Bewerbung möglich.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Engage;
