import { useState } from "react";

export function useScrollImage(): [(latest: number) => void, number, number, number, number] {
  const [factor2, setFactor2] = useState(70);
  const [height2, setHeight2] = useState(20);

  const [factor3, setFactor3] = useState(70);
  const [height3, setHeight3] = useState(20);

  const scroll = (latest: number) => {
    if (
      latest > window.innerHeight * 0.5 &&
      latest < window.innerHeight * 1.3
    ) {
      const current = latest - window.innerHeight * 0.5;
      const max = window.innerHeight * 2.1 - latest;

      const factor = current / max;

      setFactor2(factor);
    } else if (latest > window.innerHeight * 1.3) {
      setFactor2(1);
    }

    if (
      latest > window.innerHeight * 3 &&
      latest < window.innerHeight * 4
    ) {
      const current = latest - window.innerHeight * 3.2;
      const max = window.innerHeight * 4.8 - latest;

      const factor = current / max;

      setFactor3(factor);
    } else if (latest > window.innerHeight * 4) {
      setFactor3(1);
    }
  };

  return [scroll, factor2, height2, factor3, height3];
}
