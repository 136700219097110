import { Heart } from "lucide-react";
import { Link } from "react-router-dom";
import React from "react";

const Footer = () => {
  return (
    <div className="px-3 lg:px-20 text-center md:text-left py-6">
      <div className="h-[1px] bg-gray-500"></div>
      <div className="text-gray-500 flex flex-row items-center gap-2 p-2 justify-center md:justify-normal">
        <p className="text-xl">Made with </p>
        <Heart />
      </div>
      <div className="px-2 text-gray-500 flex md:flex-row flex-col justify-between">
        <p className="text-xs">Copyright © 2024 EndTheSilence e.V. Alle Rechte vorbehalten.</p>
        <div className="flex flex-row items-center justify-center md:justify-normal">
          <Link to="/data-privacy">
            <p className="text-xl">Datenschutz</p>
          </Link>
          <div className="w-[1px] h-[1.5rem] bg-gray-500 mx-2"></div>
          <Link to="/impressum">
            <p className="text-xl">Impressum</p>
          </Link>
          <div className="w-[1px] h-[1.3rem] bg-gray-500 mx-2"></div>
          <Link to="/contact">
            <p className="text-xl">Kontakt</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Footer;
