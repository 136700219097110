import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

const Impressum = () => {

    useGSAP(() => {
        gsap.to(
          ".logo",
          { filter: "invert(1)" },
        );
        gsap.to(
          ".navbar-text",
          { color: "black" }
        )
      });

  return (
    <div className="flex flex-col px-6 lg:px-40 pt-20">
      <div>
        <p className="text-4xl font-bold">Impressum</p>
      </div>

      <div className="mt-16">
        <p className="text-3xl text-gray-500">Das sind wir:</p>
      </div>

      <div className="p-2 text-gray-500">
        <p className="text-base">EndTheSilence e.V.</p>
        <p className="text-base">Uhlandstraße 15</p>
        <p className="text-base">57074 Siegen</p>
      </div>

      <div className="p-2 mt-2 text-gray-500">
        <p className="text-base">Handelsregister: VR 6881</p>
        <p>Registergericht: Amtsgericht Siegen</p>
      </div>

      <div className="p-2 text-gray-500">
        <p className="font-bold text-black">Vertreten durch:</p>
        <p>Sandra Rode</p>
        <p>Jonathan Drefs</p>
      </div>

      <div className="mt-10 text-gray-500">
        <p className="text-3xl">Kontakt</p>
        <p className="px-2">
          E-Mail:{" "}
          <a href="mailto:kontakt@endthesilence.de">kontakt@endthesilence.de</a>
        </p>
      </div>

      <div className="mt-10 text-gray-500">
        <p className="text-3xl">EU-Streitschlichtung</p>
        <p className="px-2">
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:
        </p>
        <a className="px-2">https://ec.europa.eu/consumers/odr/</a>
        <p className="px-2">
          Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht
          bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>
    </div>
  );
};
export default Impressum;
