import { useSetAtom } from "jotai";
import { textIndex } from "./atoms";
import { useState } from "react";

export function useScrollFunctions(): [(latest: number) => void, number] {
  const setActiveTextIndex = useSetAtom(textIndex)
  const [brightness, setBrightness] = useState(100)

  const scroll = (latest: number) => {
    if (
      latest > window.innerHeight * 1.7 &&
      latest < window.innerHeight * 3.5
    ) {
      const totalHeight = window.innerHeight * 1.1;
      const thirdHeight = totalHeight / 3;
  
      if (latest < thirdHeight + totalHeight) {
        setActiveTextIndex(0); // Erstes Drittel
      } else if (latest < 2 * thirdHeight + totalHeight) {
        setActiveTextIndex(1); // Zweites Drittel
      } else if (latest < 3 * thirdHeight + totalHeight) {
        setActiveTextIndex(2); // Drittes Drittel
      } else {
        setActiveTextIndex(3);
      }
    } else {
      setActiveTextIndex(0);
    }

    if(latest > window.innerHeight * 0.5 && latest < window.innerHeight) {
      const current = latest - (window.innerHeight * 0.5)
      const max = window.innerHeight * 0.5

      const factor = current / max

      setBrightness(100 - (70 * factor))
    } else if(latest > window.innerHeight) {
      setBrightness(30)
    }
  }

  return [scroll, brightness]
}