import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./components/navbar";
import Home from "./components/home/home";
import SexualViolance from "./components/sexual-violance/sexualViolance";
import Engage from "./components/engage/engage";
import Impressum from "./components/imperssum/impressum";
import Unkwown from "./components/404/404";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sexualized-violence" element={<SexualViolance />} />
        <Route path="/engage" element={<Engage />} />
        <Route path="/impressum" element={<Impressum />} />


        <Route path="*" element={<Unkwown />} />
      </Routes>
    </Router>
  );
}

export default App;
