import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

const Unkwown = () => {
  useGSAP(() => {
    gsap.to(".logo", { filter: "invert(1)" });
    gsap.to(".navbar-text", { color: "black" });
  });

  return (
    <div className="pt-36 flex justify-center">
      <div>
        <p className="text-6xl text-gray-500 font-thin">Error 404</p>
        <p className="text-xl text-gray-500">Sry diese Seite gibt es nicht.</p>
      </div>
    </div>
  );
};
export default Unkwown;
