import { useAtomValue } from "jotai";
import Footer from "../footer";
import Button from "./button";
import { currentTopic } from "./atoms";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

const SexualViolance = () => {
  const topic = useAtomValue(currentTopic);

  useGSAP(() => {
    gsap.to(
      ".logo",
      { filter: "invert(1)" },
    );
    gsap.to(
      ".navbar-text",
      { color: "black" }
    )
  });

  return (
    <div className="bg-secondary h-screen">
      <section className="lg:px-96 p-4 lg:p-0">
        <div className="pt-36">
          <p className="text-4xl lg:text-5xl font-bold">Es passiert überall.</p>
          <p className="text-xl lg:text-3xl text-gray-500 mt-4 lg:mt-8">
            Die Diversität von sexualisierter Gewalt ist gigantisch, dennoch
            liegen aktuell keine genauen Studien und Daten zu Häufigkeit vor.
            Die folgende Inhalte beziehen sich auf die Angaben durch das UBSKM
            im Jahr 2020.
          </p>
        </div>
        <div className="h-[1px] w-[400px] bg-gray-500 my-4"></div>
        <div className="grid grid-cols-3 lg:grid-cols-5 gap-2">
          <Button topic="polizei">
            <p className="text-xs lg:text-base">Polizeilich Ermittelt</p>
          </Button>
          <Button topic="dunkelziffer">
            <p className="text-xs lg:text-base">Dunkelziffer</p>
          </Button>
          <Button topic="schulklasse">
            <p className="text-xs lg:text-base">Pro Schulklasse</p>
          </Button>
          <Button topic="risiken">
            <p className="text-xs lg:text-base">Besondere Risiken</p>
          </Button>
          <Button topic="context">
            <p className="text-xs lg:text-base">Kontext</p>
          </Button>
        </div>
        <div className="pt-10 mb-16">
          {topic === null && (
            <div className="text-gray-500">
              <p className="text-5xl">300.000</p>
              <p className="text-2xl">Kinder und Jugendliche pro Jahr</p>

              <p className="mt-12 text-xl">
                Dies ist die Dunkelziffer für den sexuellen Missbrauch an
                Kindern und Jugendlichen. Diese Dunkelziffer beinhaltet alle{" "}
              </p>
            </div>
          )}
          {topic === "polizei" && (
            <div className="text-gray-500">
              <p className="text-5xl">15.520</p>
              <p className="text-2xl">
                Fälle des sexuellen Kindesmissbrauches.
              </p>

              <p className="mt-12 text-xl">
                Wurden im Jahr 2020 durch die Polizei Ermittelt und an die
                Staatsanwaltschaften weitergegeben. Seit 2018 stieg diese Zahl
                gemäß der Polizeilichen Kriminalstatistik von 12.321 auf 15.520
                weitergegebene Fälle pro Jahr an. Als Fall wird in der
                Polizeilichen Kiminalstatistik jede rechtswidrige Handlung
                bezeichnet, die im Rahmen einer Ermittlung bekannt geworden ist.
                Die Definierung des sexuellen Kindesmissbrauchs im Jahr 2022
                gemäß §§ 176-176e StGB und bis 2021 gemäß §§ 176, 176a, 176b
                StGB.
              </p>
            </div>
          )}
          {topic === "schulklasse" && (
            <div className="text-gray-500">
              <p className="text-5xl">1-2 Kinder</p>
              <p className="text-2xl">in jeder Schulklasse.</p>

              <p className="mt-12 text-xl">
                Laut der Weltgesundheitsorganisations (WHO) Sind in Europa
                ungefähr 18 Millionen Kinder und Jugendliche von sexueller
                Gewalt betroffen. Nach übertragungen auf Deutschland ist von
                rund einer Millionen Kinder und Jugendlichen auszugehen.
                Insgesamt sind zwei Drittel der Minderjährigen in Deutschland im
                Schulalter womit statistitsch davon auszugehen ist das sich rund
                600.000 betroffene Schüler in insgesamt 400.000 Klassen
                befinden.
              </p>
            </div>
          )}
          {topic === "dunkelziffer" && (
            <div className="text-gray-500">
              <p className="text-5xl">Jeder 7te</p>
              <p className="text-2xl">
                Erwachsene in Deutschland war von Kindesmissbrauch betroffen.
              </p>

              <p className="mt-12 text-xl">
                Gemäß zwei representativen Umfragen der Arbeitsgruppe von
                Professor Fegert der Kinder- und Jugendpsychiatrie an der
                Universitätsklnik Ulm im Jahr 2019 sowie einer vorangegaenen
                Studie von Höuser, Schmutzer, Brähler, Glaesmer und Heide im
                Jahr 2021 hat jeder 7te erwachsene in seiner Kindheit und Jugend
                sexuelle Gewalt erlebt.
              </p>
            </div>
          )}
          {topic === "risiken" && (
            <div className="text-gray-500">
              <p className="text-5xl">2-3 häufiger</p>
              <p className="text-2xl">
                sind Personen mit Behinderung betroffen.
              </p>

              <p className="mt-12 text-xl">
                In einer Studie durch das Bundesministerium für Famillie,
                Senioren, Frauen und Jugend (BMFSFJ) im Jahr 2013 gaben 20% bis
                34% der retrospektiv befragten Frauen mit Behinderung an,
                sexuellen Missbrauch in Kindheit und Jugend durch Erwachsene
                erlebt zu haben. Der Bevölkerungsdurchschnitt liegt bei
                durchschnittlich 10%.
              </p>
            </div>
          )}
          {topic === "context" && (
            <div className="text-gray-500">
              <p className="text-5xl">ca. 25% der Fälle</p>
              <p className="text-2xl">
                finden im engsten Famillienkreis des Opfers statt.
              </p>

              <p className="mt-12 text-xl">
                Zusätzlich hierzu finden ca. 50% der Fälle im sozialen Nahraum
                des Opfers statt. Zum sozialen Nahraum zählen beispielsweise der
                erweiterte Famillien- und Bekanntenkreis, Nachbarn oder Personen
                aus Einrichtungen oder Vereinen, welche die Kinder und
                Jugendlichen gut kennen.
              </p>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default SexualViolance;
